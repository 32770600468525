import { Col } from "antd";
import Input from "antd/lib/input/Input";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { validateNumber } from "../../../../../utils/helpers";
import RetailSelect from "../../../../Select/RetailSelect";
import RetailText from "../../../../Typography/RetailText";
import RetailFormInfo from "../../../RetailFormInfo";
import RetailFormInput from "../../../RetailFormInput";
import RetailFormRow from "../../../RetailFormRow";
import cm from "./style.module.scss";

export interface SpendingDistributionProps {
  fields: {
    spending_type: string;
    front_loaded_percentage: string;
  };
  error: boolean;
  handleFieldChange(e: any): void;
}

const SpendingDistribution = ({
  fields: { spending_type, front_loaded_percentage },
  error,
  handleFieldChange,
}: SpendingDistributionProps) => {
  const { t } = useTranslation();

  const options = ["ASAP", "FRONT_LOADED", "EVENLY"];

  const isError = error && spending_type === null;

  const handleSelectChange = (value: string) => {
    handleFieldChange({ target: { name: "spending_type", value } });
  };

  return (
    <RetailFormRow className={cc(["form-row"])}>
      <RetailFormInfo
        column={{
          number: "4",
          title: t("components.campaignForm.firstStep.spendingTitle"),
          text: t("components.campaignForm.firstStep.spendingText"),
        }}
      />
      <Col className={cm.spendingWrapper}>
        <Col className={cm.spendingContainer}>
          <RetailSelect
            placeholder={t("components.campaignForm.firstStep.spendingTitle")}
            options={options.map((option) => ({
              value: option,
              label: t(
                `components.campaignForm.firstStep.${option.toLowerCase()}`
              ),
            }))}
            value={spending_type}
            onChange={handleSelectChange}
            className={isError ? cm.error : ""}
          />
          <RetailText size="xxxs" family="poppins" className={cm.spendingText}>
            {t("components.campaignForm.firstStep.spendingSub")}
          </RetailText>
        </Col>
        {spending_type === "FRONT_LOADED" && (
          <RetailFormInput
            isFocused={front_loaded_percentage !== ""}
            label={t("components.campaignForm.firstStep.frontPercentage")}
            name="front_loaded_percentage"
            rules={[
              {
                required: true,
                pattern: /^([1-9]|[1-9][0-9]|100)$/,
                message: t(
                  "components.campaignForm.firstStep.errorStates.frontPercentage"
                ),
              },
            ]}
            className={cc(["floating", cm.spendingInput])}
          >
            <Input
              className={cc([
                "number-input floating",
                front_loaded_percentage === "" ? cm.hidePrefix : cm.showPrefix,
              ])}
              prefix="%"
              name="front_loaded_percentage"
              value={front_loaded_percentage}
              onChange={handleFieldChange}
              onKeyDownCapture={(e) => validateNumber(e)}
            />
          </RetailFormInput>
        )}
      </Col>
    </RetailFormRow>
  );
};

export default SpendingDistribution;
